import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { getLabelByValue, IMonitoringEntriesMetaInfo } from '../../store/monitoringSystem/monitoringSystemModal';
import { Grid, Typography, Box } from '@mui/material';
import { COLORS } from '../../utils/colors';

interface IMonitoringChartProps {
  metaInfo: IMonitoringEntriesMetaInfo;
  selectedApi: string;
}

const MonitoringChart = (props: IMonitoringChartProps) => {
  const { metaInfo, selectedApi } = props;

  return (
    <Grid container spacing={2} sx={{ p: 3 }}>
      <Grid item xs={12}>
        <Typography variant='h6' fontWeight='bold'>
          User Activity Monitoring
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Box sx={{ width: '100%', maxWidth: 1000 }}>
          <BarChart
            xAxis={[{ scaleType: 'band', data: [getLabelByValue(selectedApi)] }]}
            series={[
              {
                data: [metaInfo.totalSuccess],
                color: COLORS.palette.green,
                label: 'Success',
                valueFormatter: (value) => `${value}`,
              },
              {
                data: [metaInfo.totalIncomplete],
                color: COLORS.palette.blue,
                label: 'Incomplete',
                valueFormatter: (value) => `${value}`,
              },
              {
                data: [metaInfo.totalFailure],
                color: COLORS.palette.red,
                label: 'Failure',
                valueFormatter: (value) => `${value}`,
              },
            ]}
            width={1000}
            height={500}
            layout='vertical'
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default MonitoringChart;
