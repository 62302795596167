export interface IMonitoringEntry {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  status: string;
  errorMessage?: string;
  createdAt: string;
  apiName: string;
  infoSessionDate?: string;
  infoSessionTitle?: string;
  infoSessionHra?: string;
  workshopName?: string;
  programTrackName?: string;
}

export interface IMonitoringEntriesMetaInfo {
  totalRecords: number;
  currentPage: number;
  totalFailure: number;
  totalSuccess: number;
  totalIncomplete: number;
  data: IMonitoringEntry[];
  totalPages: number;
}

export const monitoringEmptyMetaInfo: IMonitoringEntriesMetaInfo = {
  totalRecords: 0,
  currentPage: 1,
  totalSuccess: 0,
  totalFailure: 0,
  totalIncomplete: 0,
  data: [],
  totalPages: 1,
};

export const emptyMonitoringEntry: IMonitoringEntry = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  status: '',
  createdAt: '',
  apiName: '',
};

export const MONITORING_STATUS = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  INCOMPLETE: 'incomplete',
};

export const API_NAME = {
  SIGN_UP: 'signUp',
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgotPassword',
  CONVERT_PROSPECTIVE: 'convertToProspective',
  SUSPEND_CLIENT: 'suspendClient',
  ENROLL_IN_PROGRAM_TRACK: 'enrollInProgramTrack',
  ENROLL_IN_WORKSHOP: 'enrollInWorkshop',
};

export const monitoringApiList = [
  { label: 'Sign Up', value: API_NAME.SIGN_UP },
  { label: 'Login', value: API_NAME.LOGIN },
  { label: 'Forgot Password', value: API_NAME.FORGOT_PASSWORD },
  { label: 'Convert to Prospective', value: API_NAME.CONVERT_PROSPECTIVE },
  { label: 'Suspend a Client', value: API_NAME.SUSPEND_CLIENT },
  { label: 'Enroll in a Program Track', value: API_NAME.ENROLL_IN_PROGRAM_TRACK },
  { label: 'Enroll in a Workshop', value: API_NAME.ENROLL_IN_WORKSHOP },
];

export function getLabelByValue(value: string): string | undefined {
  const api = monitoringApiList.find((api) => api.value === value);
  return api ? api.label : undefined;
}
