import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../config/firebaseApp';
import { Dayjs } from 'dayjs';
import { functions } from '../config/firebaseApp';
import {
  IMonitoringEntriesMetaInfo,
  IMonitoringEntry,
  MONITORING_STATUS,
  monitoringEmptyMetaInfo,
} from '../store/monitoringSystem/monitoringSystemModal';
import { toast } from 'react-toastify';
import { httpsCallable } from 'firebase/functions';

export const fetchMonitoringEntriesByDateRange = async (
  page: number,
  startDate: Dayjs | null,
  endDate: Dayjs | null,
  apiName: string
) => {
  try {
    const pageSize = 10;
    const offset = (page - 1) * pageSize;
    const monitoringRef = collection(db, 'monitoringSystem');

    const startISO = startDate?.startOf('day').toISOString() || null;
    const endISO = endDate?.endOf('day').toISOString() || null;
    if (startISO === null || endISO === null || startISO > endISO || !apiName) {
      return monitoringEmptyMetaInfo as IMonitoringEntriesMetaInfo;
    }
    const q = query(monitoringRef, where('createdAt', '>=', startISO), where('createdAt', '<=', endISO));

    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return monitoringEmptyMetaInfo as IMonitoringEntriesMetaInfo;
    }

    const entries = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as IMonitoringEntry[];
    const entiresWithApiName = entries.filter((entry) => entry.apiName === apiName);

    const successEntries = entiresWithApiName.filter((entry) => entry.status === MONITORING_STATUS.SUCCESS);
    const failureEntries = entiresWithApiName.filter((entry) => entry.status === MONITORING_STATUS.FAILURE);
    const incompleteEntries = entiresWithApiName.filter((entry) => entry.status === MONITORING_STATUS.INCOMPLETE);

    const totalPages = Math.ceil(entiresWithApiName.length / pageSize);
    const limitedEntries = entiresWithApiName.slice(offset, offset + pageSize);
    const result: IMonitoringEntriesMetaInfo = {
      totalRecords: entiresWithApiName.length,
      currentPage: page,
      totalSuccess: successEntries.length,
      totalFailure: failureEntries.length,
      totalIncomplete: incompleteEntries.length,
      data: limitedEntries,
      totalPages: totalPages,
    };

    return result;
  } catch (error) {
    toast.error('Failed to fetch monitoring entries!');
  }
};

export const searchMonitoringEntry = async (
  page: number,
  apiName: string,
  searchQuery: string,
  searchQueryType: string
) => {
  try {
    if (!searchQuery) {
      throw new Error('Required arguments are either null or undefined');
    }
    if (searchQuery.includes('@')) {
      searchQuery = searchQuery.split('@')[0];
    }
    const pageSize = 10;
    const offset = (page - 1) * pageSize;
    const response: any = await httpsCallable(
      functions,
      'searchMonitoringEntryByNameEmail'
    )({ query: searchQuery, type: searchQueryType });
    const monitoringData = response.data.data;
    const updatedData = monitoringData.map((item: any) => {
      return {
        ...item,
        firstName: item.name.split(' ')[0],
        lastName: item.name.split(' ')[1],
      };
    });
    const entiresWithApiName = updatedData.filter((entry: any) => entry.apiName === apiName);
    const successEntries = entiresWithApiName.filter((entry: any) => entry.status === MONITORING_STATUS.SUCCESS);
    const failureEntries = entiresWithApiName.filter((entry: any) => entry.status === MONITORING_STATUS.FAILURE);
    const incompleteEntries = entiresWithApiName.filter((entry: any) => entry.status === MONITORING_STATUS.INCOMPLETE);

    const totalPages = Math.ceil(entiresWithApiName.length / pageSize);
    const limitedEntries = entiresWithApiName.slice(offset, offset + pageSize);
    const result: IMonitoringEntriesMetaInfo = {
      totalRecords: entiresWithApiName.length,
      currentPage: page,
      totalSuccess: successEntries.length,
      totalFailure: failureEntries.length,
      totalIncomplete: incompleteEntries.length,
      data: limitedEntries,
      totalPages: totalPages,
    };

    return result;
  } catch (error) {
    toast.error('Failed to search monitoring entries!');
  }
};

export const fetchAllMonitoringEntriesByDate = async (date: Dayjs, apiName: string) => {
  try {
    const monitoringRef = collection(db, 'monitoringSystem');
    const startISO = date.startOf('day').toISOString();
    const endISO = date.endOf('day').toISOString();

    if (!apiName) {
      throw new Error('API name is required');
    }

    const q = query(monitoringRef, where('createdAt', '>=', startISO), where('createdAt', '<=', endISO));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return [];
    }

    const entries = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as IMonitoringEntry[];
    const entiresWithApiName = entries.filter((entry) => entry.apiName === apiName);

    return entiresWithApiName;
  } catch (error) {
    toast.error('Failed to fetch monitoring entries!');
    return [];
  }
};
