export const caseManagerTypeOptions = [
  { id: 1, value: 'Jobs-Plus', label: 'Job Plus' },
  { id: 2, value: 'Career Service', label: 'Career Service' },
  { id: 3, value: 'PINCC Partner', label: 'PINCC Partner' },
  { id: 4, value: 'Administration for Children Services', label: 'Administration for Children Services' },
  { id: 5, value: 'NYCHA', label: 'NYCHA' },
  { id: 6, value: 'Office of Child Support Services', label: 'Office of Child Support Services' },
  { id: 7, value: 'Business Link', label: 'Business Link' },
  { id: 8, value: 'NY Public Library BX', label: 'NY Public Library BX' },
  { id: 9, value: 'NY Queens', label: 'NY Queens' },
];

export const caseManagerTypes = {
  JOB_PLUS: 'Jobs-Plus',
  CAREER_SERVICE: 'Career Service',
  PINCC_PARTNER: 'PINCC Partner',
  ACS: 'Administration for Children Services',
  NYCHA: 'NYCHA',
  OCSS: 'Office of Child Support Services',
  BUSINESS_LINK: 'Business Link',
  NYPL_BX: 'NY Public Library BX',
  NYPL_QNS: 'NY Queens',
};

export const getReferralSourceByCaseManagerType = (caseManagerType: string) => {
  if (!caseManagerType) return '';
  switch (caseManagerType) {
    case caseManagerTypes.JOB_PLUS:
      return 'Jobs-Plus';
    case caseManagerTypes.CAREER_SERVICE:
      return 'Career Service';
    case caseManagerTypes.PINCC_PARTNER:
      return 'PINCC Partner';
    case caseManagerTypes.ACS:
      return 'Administration for Children Services (ACS)';
    case caseManagerTypes.NYCHA:
      return 'NYCHA';
    case caseManagerTypes.OCSS:
      return 'Office of Child Support Services (OCSS)';
    case caseManagerTypes.BUSINESS_LINK:
      return 'Business Link';
    case caseManagerTypes.NYPL_BX:
      return 'NY Public Library Bx, NY, Queens';
    case caseManagerTypes.NYPL_QNS:
      return 'NY Public Library Bx, NY, Queens';
    default:
      return '';
  }
};
