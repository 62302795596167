import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../config/firebaseApp';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { toast } from 'react-toastify';

export const syncData = async () => {
  const response = await httpsCallable(functions, 'syncData')();

  return response.data;
};

export const getLastSyncDate = async () => {
  try {
    const syncDataRef = collection(db, 'syncData');

    const q = query(syncDataRef, orderBy('lastSyncAt', 'desc'), limit(1));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const earliestDocument = querySnapshot.docs[0].data();
      return earliestDocument;
    }
    return null;
  } catch (error: any) {
    toast.error('Error Retrieving Last Sync Date!');
  }
};

export const syncCSVClientData = async () => {
  try {
    const response: any = await httpsCallable(functions, 'generateAndSyncClientData')();
    if (response.data.success) {
      toast.success('Client Data CSV Uploaded Successfully!');
    }
  } catch (error: any) {
    toast.error('Client Data CSV Uploading Failed!');
  }
};

export const syncUnemployedParticipants = async () => {
  try {
    const response: any = await httpsCallable(functions, 'exportUnemployedUnenrolledParticipants')();
    if (response.data.success) {
      toast.success('Unemployed Client Data CSV Uploaded Successfully!');
    }
  } catch (error: any) {
    toast.error('Client Data CSV Uploading Failed!');
  }
};

export const syncClientsRemainingData = async () => {
  try {
    const response: any = await httpsCallable(functions, 'syncClientsRemainingData')();
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Error While syncing client remaining data!');
    }
  } catch (error) {
    toast.error('Error While syncing client remaining data!');
  }
};

export const updateExistingClientsInES = async () => {
  try {
    const response: any = await httpsCallable(functions, 'updateExistingClientsInElasticsearch')();
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Error While Updating Existing Cliets Elasticsearch!');
    }
  } catch (error) {
    toast.error('Error While Updating Existing Cliets Elasticsearch!');
  }
};

export const syncClientDataToClientList = async () => {
  try {
    const response: any = await httpsCallable(functions, 'syncClientDataToClientListCollection')();
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Error While Creating Client List Data!');
    }
  } catch (error) {
    toast.error('Error While Creating Client List Data!');
  }
};
export const addDummyEntriesInClientList = async () => {
  try {
    const response: any = await httpsCallable(functions, 'addDummyClientsAndIndexToElasticsearch')();
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Failed to add dummy entries!');
    }
  } catch (error) {
    toast.error('Failed to add dummy entries!');
  }
};

export const deleteDummyEntriesFromClientList = async () => {
  try {
    const response: any = await httpsCallable(functions, 'deleteDummyEntries')();
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Failed to Delete Dummy Entries!');
    }
  } catch (error) {
    toast.error('Failed to Delete Dummy Entries!');
  }
};

export const deleteDummyEntriesFromElastic = async () => {
  try {
    const response: any = await httpsCallable(functions, 'deleteDummyEntriesFromElastic')();
    if (response.data.success) {
      toast.success(response.data.message);
    } else {
      toast.error('Failed to Delete Dummy Entries!');
    }
  } catch (error) {
    toast.error('Failed to Delete Dummy Entries!');
  }
};

export const syncClientName = async () => {
  try {
    const response: any = await httpsCallable(functions, 'syncClientNameWithCSVData')();
    if (response.data.success) {
      toast.success('Client Name Synced Successfully!');
    }
  } catch (error: any) {
    toast.error('Syncing Failed!');
  }
};

export const syncInterestClients = async () => {
  try {
    const response: any = await httpsCallable(functions, 'syncInterestedInForClients')();
    if (response.data.success) {
      toast.success('InterestedIn Synced Successfully For Clients!');
    }
  } catch (error: any) {
    toast.error('Syncing Failed!');
  }
};

export const batchUpdateClientActiveStatus = async () => {
  try {
    const response: any = await httpsCallable(functions, 'syncClientsStatusToActive')();
    if (response.data.success) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error('Failed To Update Client Active Status In Batch!');
  }
};

export const updateSuspendedClientsStatus = async () => {
  try {
    const response: any = await httpsCallable(functions, 'updateSuspendedClientStatus')();
    if (response.data.success) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error('Failed To Update Suspended Client Status!');
  }
};

export const addCreatedDateInStaff = async () => {
  try {
    const response: any = await httpsCallable(functions, 'syncCreatedDateInStaffFromAuditLogs')();
    if (response.data.success) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error('Failed To Add Created Date In Staff!');
  }
};

export const listClientsWithoutPrivacyConsent = async () => {
  try {
    const response: any = await httpsCallable(functions, 'listClientsWithoutPrivacyConsent')();
    if (response.data.success) {
      toast.success('Without Privacy Consent Client Data CSV Uploaded Successfully!');
    }
  } catch (error: any) {
    toast.error('Client Data CSV Uploading Failed!');
  }
};
export const listEnrolledClientsWithoutPrivacyConsent = async () => {
  try {
    const response: any = await httpsCallable(functions, 'listEnrolledClientsWithoutPrivacyConsent')();
    if (response.data.success) {
      toast.success('Enrolled and Without Privacy Consent Client Data CSV Uploaded!');
    }
  } catch (error: any) {
    toast.error('Client Data CSV Uploading Failed!');
  }
};
export const listHiredClientsWithoutPrivacyConsent = async () => {
  try {
    const response: any = await httpsCallable(functions, 'listHiredClientsWithoutPrivacyConsent')();
    if (response.data.success) {
      toast.success('Hired and Without Privacy Consent Client Data CSV Uploaded!');
    }
  } catch (error: any) {
    toast.error('Client Data CSV Uploading Failed!');
  }
};
export const listCompletedClientWithoutPrivacyConsent = async () => {
  try {
    const response: any = await httpsCallable(functions, 'listCompletedClientWithoutPrivacyConsent')();
    if (response.data.success) {
      toast.success('Completed and Without Privacy Consent Client Data CSV Uploaded!');
    }
  } catch (error: any) {
    toast.error('Client Data CSV Uploading Failed!');
  }
};
export const clientEmploymentModuleStatusUpdate = async () => {
  try {
    const response: any = await httpsCallable(functions, 'clientEmploymentModuleStatusUpdate')();
    if (response.data.success) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error(' Error While Syncing EmploymentModuleStatus field!');
  }
};
export const syncClientEmploymentStatusFromAuditLogs = async () => {
  try {
    const response: any = await httpsCallable(functions, 'syncClientEmploymentStatusFromAuditLogs')();
    if (response.data.success) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error(' Error While Syncing Employment Status field!');
  }
};
export const syncEmploymentModuleStatusWithCsv = async () => {
  try {
    const response: any = await httpsCallable(functions, 'syncEmploymentModuleStatusWithCsv')();
    if (response.data.success) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error(' Error While Syncing Employment Module Status With CSV!');
  }
};
export const getClientsMissingPrivacyConsent = async () => {
  try {
    const response: any = await httpsCallable(functions, 'getClientsMissingPrivacyConsent')();
    if (response.data.success) {
      toast.success('Client Data missing privacy consent CSV Uploaded Successfully!');
    }
  } catch (error: any) {
    toast.error('Client Data CSV Uploading Failed!');
  }
};
export const syncClientsPrivacyConsent = async () => {
  try {
    const response: any = await httpsCallable(functions, 'syncClientsPrivacyConsent')();
    if (response.data.success) {
      toast.success('Clients Privacy Consent Synced Successfully!');
    }
  } catch (error: any) {
    toast.error('Error While Syncing Privacy Consent of Clients!');
  }
};
export const formatEmailsToLowercaseInClients = async () => {
  try {
    const response: any = await httpsCallable(functions, 'formatEmailsToLowercaseInClients')();
    if (response.data.success) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error('Error While Syncing Privacy Consent of Clients!');
  }
};
export const formatEmailsToLowercaseInCsvData = async () => {
  try {
    const response: any = await httpsCallable(functions, 'formatEmailsToLowercaseInCsvData')();
    if (response.data.success) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error('Error While Syncing Privacy Consent of Clients!');
  }
};
export const moveClientsInOtherInfoSession = async () => {
  try {
    const response: any = await httpsCallable(functions, 'moveClientsInOtherInfoSession')();
    if (response.data.success) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error('Error While Moving Clients To Other Info Session!');
  }
};
export const resetInfoSessionAttendance = async () => {
  try {
    const response: any = await httpsCallable(functions, 'resetInfoSessionAttendance')();
    if (response.data.success) {
      toast.success(response.data.message);
    }
  } catch (error: any) {
    toast.error('Error While reseting attendance for info session!');
  }
};
