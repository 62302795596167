import { signInWithEmailAndPassword } from 'firebase/auth';
import { AppDispatch } from '../store/store';
import { auth } from '../config/firebaseApp';
import { getRole, saveAuditLogOnLoginFail } from '../services/firebase.service';
import { getUserInfoByUid, saveMonitoringEntry } from '../services/client.service';
import { UserRolesEnum } from './rolesEnum';
import { updateUser } from '../store/user/userAction';
import { API_NAME, MONITORING_STATUS } from '../store/monitoringSystem/monitoringSystemModal';
import dayjs from 'dayjs';
import { fetchAllMonitoringEntriesByDate } from '../services/monitoringSystem.service';

export const loginUser = async (email: string, password: string, dispatch: AppDispatch) => {
  let userInfo: any = null;
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    if (userCredential.user && userCredential.user.uid) {
      const role = await getRole(auth);
      userInfo = await getUserInfoByUid(
        userCredential.user.uid,
        !(role === UserRolesEnum.PROSPECTIVE_CLIENT || role === UserRolesEnum.CLIENT)
      );
      if (userInfo) {
        dispatch(updateUser(userInfo));
        const isAlreadyLoginToday = await fetchAllMonitoringEntriesByDate(dayjs(), API_NAME.LOGIN);
        if (isAlreadyLoginToday.length === 0) {
          await saveMonitoringEntry({
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            email: userInfo.email,
            mobile: userInfo.mobile,
            status: MONITORING_STATUS.SUCCESS,
            createdAt: dayjs().toISOString(),
            apiName: API_NAME.LOGIN,
          });
        }
      }
    }
  } catch (e: any) {
    await saveMonitoringEntry({
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
      mobile: userInfo.mobile,
      status: MONITORING_STATUS.FAILURE,
      createdAt: dayjs().toISOString(),
      apiName: API_NAME.LOGIN,
    });
    if (e.message === 'Firebase: Error (auth/wrong-password).') {
      saveAuditLogOnLoginFail(email);
      throw new Error('Invalid credentials');
    } else {
      saveAuditLogOnLoginFail(email);
      throw e;
    }
  }
};
